import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Button, Section, Box, Select, Input, Text } from "../components/Core";

import PageWrapper from "../components/PageWrapper";

import Hero from "../sections/common/Hero";

import { post } from 'axios';
import dataService from "../services/data-service";
import imgIcon from "../assets/image/png/thumbs-shape.png";
import ReCAPTCHA from "react-google-recaptcha";
import CTA from "../sections/download/CTA";

const FormStyled = styled.form``;

const businessTypes = [
    { value: "", label: "Select business type" },
    { value: "Business", label: "Business" },
    { value: "Professional", label: "Professional" }
];

let categoryListArr = [
  { value: "", label: "Select category type" },
];

let regionListArr = [
  { value: "", label: "Select city" },
];

const ContentIcon = styled.div`
  width: 118px;
  height: 118px;
  background-color: ${({ theme }) => theme.colors.secondary};
  border-radius: 500px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 48px;
`;

class GetListed extends React.Component {
  constructor() {
    super();
    this.onFileSelect = this.onFileSelect.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.selectChange = this.selectChange.bind(this);
    this.captchaChange = this.captchaChange.bind(this);
    this.state = {
      data: null,
      file: null,
      alert: {
        display: false,
        message: ''
      },
      captchaTokenVal: null,
      locatedAt: '',
      showLocatedAt: false
    };

    this.getRegion();
    this.getCategories();
  }
  recaptchaRef = React.createRef();
  
  onFileSelect(e) {
    let files = e.target.files;
   
    if (files.length > 0) {
      for (let fl=0; fl < files.length; fl++) {
        const currentFile = files[fl];
        const fileIndex = fl + 1;
        const reader = new FileReader(currentFile);
        reader.readAsDataURL(currentFile);
        reader.onload = () => {
          // set image and base64'd image data in component state
          this.setState({
            [`vendorImage${fileIndex}`] : {
              data: reader.result,
              name: currentFile.name
            }
          });
         
        }
      }
    }
  }

  handleInputChange(event) {
    const target = event.target;
    // const value = target.value;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    // console.log(" target value - ", name, value);
    
    this.setState({
      [name]: value
    });
  }

  selectChange(inputVal, inputEle){
    console.log(" input val ", inputVal, inputEle.name);
    const selectName = inputEle.name;
    if(inputVal && inputVal.value){
      this.setState({
        [selectName]: inputVal.value
      });

      if(selectName === 'category' && inputVal.id){
        this.setState({
          categoryId: inputVal.id
        });
      }
      if(selectName === 'region' && inputVal.id){
        this.setState({
          regionId: inputVal.id
        });
        if(inputVal.value === 'Addis Ababa'){
          this.setState({showLocatedAt: true});
        }else {
          this.setState({showLocatedAt: false});
        }
      }

    }
  }

  getRegion = async () => {
    const regionList = await dataService.getRegionList();
    if(regionList.length > 0){
      for (const currentRegion of regionList) {
        regionListArr.push({
          'value': currentRegion.name,
          'label': currentRegion.name,
          'id': currentRegion.objectId
        });
      }
      console.log(" region ", regionListArr);
      
    }
  };

  getCategories = async () => {
    const categoryList = await dataService.getCategoryList();
    if(categoryList.length > 0){
      for (const currentCategory of categoryList) {
        categoryListArr.push({
          'value': currentCategory.name,
          'label': currentCategory.name,
          'id': currentCategory.objectId
        });
      }
      // console.log(" cat ", categoryListArr);
    }
  };

  captchaChange = async (value) => {
    console.log(" captcha change ", value);
    console.log("captcha val ok ", this.recaptchaRef.current.getValue());
    // const captchaRes = await dataService.captchaVerify(this.recaptchaRef.current.getValue());
    // console.log(" captcha res ", captchaRes);
    this.setState({
      captchaTokenVal: this.recaptchaRef.current.getValue()
    });
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    
    // console.log("captcha val ", this.state.captchaTokenVal);

    const { name, vendorType, category, region, address, description, phone, email, vendorImage1, vendorImage2, vendorImage3, categoryId, regionId, locatedAt } = this.state;
    if(!vendorType) {
      alert(" Please select Business type");
      return false;
    }else if(!category) {
      alert(" Please select Category");
      return false;
    }else if(!region){
      alert(" Please select Region");
      return false;
    }
    // const url = '';
    const url = 'https://w2wn4ej9j9.execute-api.us-east-1.amazonaws.com/dev/get-listed';
    let formBody = {
      name, vendorType, category, region, address, phone, email, description, locatedAt
    };

    if(vendorImage1){
      formBody.vendorImage1 = vendorImage1;
    }
    if(vendorImage2){
      formBody.vendorImage2 = vendorImage2;
    }
    if(vendorImage3){
      formBody.vendorImage3 = vendorImage3;
    }

    if(categoryId){
      formBody.categoryId = categoryId;
    }
    if(regionId){
      formBody.regionId = regionId;
    }

    const captchaToken = this.recaptchaRef.current.getValue();
    if(!captchaToken){
      alert(" Please select captcha");
      return false;
    }

    try {
      await dataService.captchaVerify(captchaToken);
      const config = {
          headers: {
              'content-type': 'application/json'
          }
      }
      return  post(url, formBody, config)
      .then((submitted) => {
        this.recaptchaRef.current.reset();
        if(submitted.status === 200){
          console.log(" submitted ", submitted);
          this.setState({
            alert: {
              display: true,
              message: 'Thank you for posting your business information, it will be reviewed and posted in few hours.'
            }
          });
        }else {
          this.setState({
            alert: {
              display: true,
              message: 'Failed to submit business details.'
            }
          });
        }
      }, (formErr) => {
        console.log(" formErr ", formErr);
        this.recaptchaRef.current.reset();
        this.setState({
          alert: {
            display: true,
            message: 'Failed to submit business details.'
          }
        });
      });
    } catch (formSubErr) {
      this.setState({
        alert: {
          display: true,
          message: 'Failed to submit business details.'
        }
      });
    }

  }

  render() {
    
    const { alert, showLocatedAt } = this.state;

    return (
      <>
        <PageWrapper footerDark>
          <Hero title="Add Business">
            Get listed on Kesto to app users can find you easily. 
            <br/>
            Download kesto app and you can add business from the App.
          </Hero>
          <Section pt={0} mt={0}>
            <Container>

              <CTA />
              
            </Container>
          </Section>
        </PageWrapper>
      </>
    );
  }
}

export default GetListed;
